.AppOurServices {
  padding: 95px 0;
  background-color: var(--color-gray-100);

  @media (max-width: 1440px) {
    padding: 75px 0;
  }

  @media (max-width: 1024px) {
    padding: 45px 0;
  }

  .AppOurServices_Inner {
    display: flex;
    gap: 32px;

    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.AppOurServices_Title {
  font-family: var(--font-base);
  font-style: normal;
  font-weight: var(--fw-b);
  font-size: var(--fs-d-lg);
  line-height: 1.3;
  color: var(--color-black);
  // word-break: break-word;
  word-break: normal;

  @media (max-width: 1440px) {
    font-size: var(--fs-d-md);
  }
}

.AppOurServices_Left {
  position: sticky;
  top: 30px;
  height: 408px !important;

  @media (max-width: 1280px) {
    position: relative;
    height: 278px !important;
  }

  @media (max-width: 1024px) {
    height: 258px !important;
  }

  @media (max-width: 420px) {
    height: 298px !important;
  }

  .AppOurServices_Left_Title {
    max-width: 370px;
    margin-bottom: 48px;

    @media (max-width: 1440px) {
      margin-bottom: 25px;
    }
  }

  @media (max-width: 1280px) {
    max-width: 768px;
  }
}

.AppOurServices_Right {
  display: grid;
  grid-template-columns: 384px 384px;
  grid-gap: 32px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .AppOurService {
    background-color: var(--color-black);
    border-radius: 30px;

    &:hover {
      .AppOurService_Inner {
        transform: translate(-10px, -10px);
      }
    }

    .AppOurService_Inner {
      display: flex;
      flex-direction: column;
      padding: 24px;
      height: 100%;
      border-radius: 28px;
      transition: all 0.3s ease;
      background-color: var(--color-white);
    }

    figure {
      height: 70px;
      width: 70px;
      margin-bottom: 20px;
    }

    h2 {
      font-family: var(--font-base);
      font-weight: var(--fw-m);
      font-size: var(--fs-d-xs);
      line-height: 1.3;
      color: var(--color-black);
      margin-bottom: 16px;

      @media (max-width: 1280px) {
        font-size: var(--fs-t-xl);
      }
    }

    .AppOurService_Text {
      font-weight: var(--fw-r);
      font-size: var(--fs-t-md);
      color: var(--color-gray-700);
      margin-bottom: 20px;

      @media (max-width: 1280px) {
        font-size: var(--fs-t-sm);
      }
    }

    .AppOurService_Link {
      display: flex;
      align-items: flex-end;
      flex: 1;
    }

    .AppOurService_Arrow {
      display: flex;
      align-items: center;
      gap: 17px;

      strong {
        font-family: var(--font-base);
        font-weight: var(--fw-m);
        font-size: var(--fs-t-md);
        height: 1.3em;
        color: var(--color-black);

        @media (max-width: 1280px) {}
      }

      span {
        transition: all 0.3s ease;
      }

      svg {
        transition: all 0.3s ease;
      }

      &:hover {

        //span {
        //  transform: translateX(10px);
        //}
        svg {
          transform: translateX(10px);
        }
      }
    }
  }
}